.header {
	padding: 10px 16px;
	background: #555;
	color: #f1f1f1;
}

.mt-100 {
	margin-top: 100px;
}
ul.skuList {
	list-style-type: none;
	margin: 0;
	padding: 5px 0 0 0;
}

table {
	border-spacing: 30px;
}
th {
	white-space: nowrap;
}
td {
	padding: 15px;
	vertical-align: middle;
}
