.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.skuOptions {
	/* border: 1px solid red; */
	padding-top: 15px;
}
.formParent {
	display: flex;
	/* justify-content: center; */
}
.twoInputs {
	display: flex;
}
.twoInputs > .form-control {
	width: 58px;
	margin-left: 7px;
	font-size: 13px;
}

#demo-simple-select {
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	padding: 0.375rem 0.375rem;
	font-size: 13px;
	padding-right: 20px;
	/* border-bottom: none; */
}
/* #demo-simple-select::before {
	border-bottom: none;
} */
.twoInputs input[type="number"]::-webkit-inner-spin-button,
.twoInputs input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.MuiInput-underline:before {
    border-bottom:none !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}